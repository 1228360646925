<script setup>
import { computed, defineComponent, onMounted, ref } from "vue";
import axios from "axios";
import Item from "./Item.vue";
import Paginate from "vuejs-paginate-next";
import VueScrollTo from "vue-scrollto";
import "../../../sass/transition-styles.scss";

defineComponent({
  components: {
    Item,
  },
});

/* eslint-disable no-undef */
const props = defineProps({
    is_front: {
      type: Boolean,
      required: true,
      default: false,
    },
  }),
  jsonPath4List = `/blog/mt_blog_list.json?${Date.now()}`,
  jsonPath4Categories = `/blog/mt_blog_category_list.json?${Date.now()}`,
  jsonPath4Clubs = `/blog/mt_blog_club_list.json?${Date.now()}`,
  init_row = props.is_front ? 8 : 12,
  originalList = ref([]),
  originalCategories = ref([]),
  originalClubs = ref([]),
  originalYears = ref([]),
  selectedCategory = ref("all"),
  selectedClub = ref(""),
  selectedYear = ref(""),
  currentPage = ref(1),
  // eslint-disable-next-line no-promise-executor-return
  delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
  clickCallback = async (pageNum) => {
    currentPage.value = Number(pageNum);
    await VueScrollTo.scrollTo("#news-pic-list", 500, { offset: -100 });
    await delay(500);
  },
  filteredList = computed(() => {
    return originalList.value
      .filter((item) => {
        if (selectedCategory.value === "all") return true;
        return item.category.some(
          (category) => category.class === selectedCategory.value
        );
      })
      .filter((item) => {
        if (selectedClub.value === "") return true;
        return item.clubs.includes(selectedClub.value);
      })
      .filter((item) => {
        if (selectedYear.value === "") return true;
        return Number(item.year) === Number(selectedYear.value);
      });
  }),
  displayList = computed(() => {
    const current = currentPage.value * init_row,
      start = current - init_row;
    return filteredList.value
      .map((item) => {
        return {
          ...item,
          key: Math.floor(Math.random() * 100000),
        };
      })
      .slice(start, current);
  }),
  getPageCount = computed(() => {
    return Math.ceil(filteredList.value.length / init_row);
  }),
  changeFilter = () => {
    clickCallback(1);
  },
  clickCategory = (category) => {
    selectedCategory.value = category;
  },
  groupedByYear = (list) => {
    return list.reduce((acc, item) => {
      const { year } = item;
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(item);
      return acc;
    }, {});
  };

onMounted(async () => {
  originalList.value = await axios.get(jsonPath4List).then((res) => {
    return res.data
      .map((item) => {
        return {
          ...item,
          clubs: item.club ? item.club.split(",") : null,
          year: new Date(item.date.replace(/-/gu, "/")).getFullYear(),
        };
      })
      .sort((a, b) => {
        const dateA = new Date(a.date),
          dateB = new Date(b.date);
        return dateB - dateA;
      });
  });
  originalCategories.value = await axios
    .get(jsonPath4Categories)
    .then((res) => res.data);
  originalClubs.value = await axios.get(jsonPath4Clubs).then((res) => res.data);
  originalYears.value = Object.keys(groupedByYear(originalList.value)).sort(
    (a, b) => b - a
  );
});
</script>

<template>
  <div v-if="props.is_front" class="c-index-newsHead">
    <h2 class="c-index-ttl01" data-text="KAN-ICHI BLOG">関一ブログ</h2>
    <div class="c-index-newsHead__category">
      <div class="news__filter c-index-newsHead__filter">
        <a
          class="c-index-newsHead__item _all_"
          :class="{ _active_: selectedCategory === 'all' }"
          data-filter="all"
          href=""
          role="button"
          @click.prevent="clickCategory('all')"
          >すべて</a
        >
        <a
          v-for="(row, key) in originalCategories"
          :key="`category-${key}`"
          class="c-index-newsHead__item"
          :class="
            (`_${row.class}_`, { _active_: selectedCategory === row.class })
          "
          :data-filter="row.class"
          href=""
          role="button"
          @click.prevent="clickCategory(row.class)"
          v-html="row.label"
        />
      </div>
    </div>
  </div>

  <h2 v-if="!props.is_front" class="e-h2">関一ブログ一覧</h2>
  <div v-if="!props.is_front" class="c-blog__head">
    <div class="e-form-select">
      <select
        v-model="selectedCategory"
        class="e-form-select__item"
        @change="changeFilter"
      >
        <option value="all">ブログカテゴリ</option>
        <option
          v-for="(row, key) in originalCategories"
          :key="`category-${key}`"
          :value="row.class"
          v-html="row.label"
        />
      </select>
    </div>
    <div class="e-form-select">
      <select
        v-model="selectedClub"
        class="e-form-select__item"
        @change="changeFilter"
      >
        <option value="">部活動カテゴリ</option>
        <option
          v-for="(row, key) in originalClubs"
          :key="`club-${key}`"
          :value="row"
          v-html="row"
        />
      </select>
    </div>
    <div class="e-form-select">
      <select
        v-model="selectedYear"
        class="e-form-select__item"
        @change="changeFilter"
      >
        <option value="">過去記事アーカイブ</option>
        <option
          v-for="(row, key) in originalYears"
          :key="`year-${key}`"
          :value="row"
          v-html="`${row}年の記事`"
        />
      </select>
    </div>
  </div>

  <transition-group
    v-if="filteredList.length"
    name="fade"
    tag="div"
    class="news__content c-grid _col4_"
    :class="{ 'c-index-newsBody': props.is_front }"
  >
    <item v-for="item in displayList" :key="`item-${item.key}`" :item="item" />
  </transition-group>
  <p v-else class="u-mt-l">該当するブログ記事はありません。</p>

  <paginate
    v-if="!props.is_front && filteredList.length"
    v-model="currentPage"
    :page-count="getPageCount"
    :page-range="3"
    :margin-pages="1"
    :click-handler="clickCallback"
    prev-text='<span class="icon-angle-left"/>'
    next-text='<span class="icon-angle-right" />'
    container-class="m-pagination"
    page-class="m-pagination__item"
    page-link-class="m-pagination__link "
    prev-class="m-pagination__item"
    prev-link-class="m-pagination__link "
    next-class="m-pagination__item"
    next-link-class="m-pagination__link "
    break-view-class="m-pagination__item"
    break-view-link-class="m-pagination__link "
    active-class="_current_"
    disabled-class="_disabled_"
    :hide-prev-next="true"
  />
</template>

<style scoped></style>
