<script setup>
/* eslint-disable no-undef */
const props = defineProps({
    item: {
      type: Object,
      required: true,
      /* eslint-disable no-undef */
      default: () => ({}),
    },
  }),
  formatDate = (dateString) => {
    const date = new Date(dateString.replace(/-/gu, "/")),
      year = date.getFullYear(),
      // Months are zero indexed, so +1 and pad with 0
      month = `0${date.getMonth() + 1}`.slice(-2),
      day = `0${date.getDate()}`.slice(-2);
    return `${year}/${month}/${day}`;
  };
</script>

<template>
  <a
    :href="props.item.permalink"
    class="panel c-news-list__item __col"
    data-category="info"
  >
    <div class="c-news-list__img">
      <img :src="props.item.thumb" :alt="props.item.title" class="__elem" />
    </div>
    <div class="c-news-list__body">
      <div class="c-news-list__label">
        <div
          v-for="(category, key) in props.item.category"
          :key="`${props.item.id}-category-${key}`"
          class="e-label __label"
          :class="`_${category.class}_`"
          v-html="category.label"
        />
        <div
          v-for="(club, key) in props.item.clubs"
          :key="`${props.item.id}-club-${key}`"
          class="e-label _club-name_ __label"
          v-html="club"
        />
      </div>
      <time :datetime="props.item.date" class="__date">{{
        formatDate(props.item.date)
      }}</time>
      <p class="__text" v-html="props.item.title" />
    </div>
  </a>
</template>

<style scoped></style>
